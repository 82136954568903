<template>
	<div id="UnpackingRules">
		<div class="UnpackingRules-box">
			<div class="Title">
				<p>GKDCSGO用户协议</p>
				<div class="Title-lower"></div>
			</div>
			<div class="UnpackingRules-box-info">
				<p>感谢访问GKDCSGO（包括但不限于GKDCSGO手机客户端或其他形式，以下合称&ldquo;GKDCSGO&rdquo;或&ldquo;平台&rdquo;）！ GKDCSGO 是CSGO饰品盲盒网站，致力于为玩家提供更有趣的CSGO饰品获得方式。</p>
<p>若您不同意或接受本协议中的任何条款，请您不要使用任何GKDCSGO的产品或服务。若您勾选&ldquo;我已满18岁，并阅读和同意《用户协议》和《隐私政策》，承诺理性消费&rdquo;，并进行注册或通过接入、访问、浏览GKDCSGO，使用我们提供的服务，即视为您已阅读、理解本《用户协议》，并同意受本《用户协议》约束。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款。限制、免责条款可能以加粗和下划线形式提示您注意，您应重点阅读。双方确认，前述免除或者限制责任的条款并非属于《中华人民共和国民法典》第497条规定的&ldquo;减轻其责任、加重对方责任、限制对方&rdquo;的条款或其他可适用的法律中对于格式条款的特别约定，双方同意该条款的合法性及有效性。</p>
<p>&nbsp;</p>
<p>若您对本《用户协议》有任何疑问，可随时按照本《用户协议》第十七条列明的方式联系我们。</p>
<p>&nbsp;</p>
<p>平台重视用户使用平台的反馈及体验，并基于此进行了平台升级。为与平台升级同步，我们更新用户协议，主要体现在出于账号保护之目的，补充可能暂缓提供部分功能的情形。</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>一、服务内容</p>
<p>1.1本《用户协议》项下的具体服务是指我们在GKDCSGO任何相关平台上不时向用户提供的全部或部分及服务（以下简称&ldquo;产品&rdquo;或&ldquo;服务&rdquo;）。请注意，您必须年满18周岁，方可使用GKDCSGO的服务。</p>
<p>&nbsp;</p>
<p>1.2 GKDCSGO提供的饰品均为CSGO饰品，仅可用于CSGO游戏中。GKDCSGO的全部或部分服务及各业务板块（包括但不限于ROLL房、盲盒、汰换、积分商城、追梦、竞技场等）均系基于公平合理的算法提供的，该算法项下获得饰品的概率可能受到饰品价值等多种元素的影响，且其具有随机性的特点！我们也将在每日上午对概率进行常规更新，或尽快对库存量不足的饰品或皮肤进行替换处理，以使得玩家得到的信息最新且准确。我们建议您仔细阅读各板块的规则关注每日概率的变化，掌握饰品动态并坚持理性消费！</p>
<p>&nbsp;</p>
<p>1.3请注意，GKDCSGO上所有盲盒产品或以盲盒形式抽取或掉落的具有随机性的产品均具有射幸性质，开启后期待利益一次用尽。您在此确认并同意，所获饰品不可退货，不得在平台兑换为其他饰品，并且我们在GKDCSGO的任何平台、由GKDCSGO开设的官方沟通社交群（包括但不限于QQ群）及线下均不提供以法定货币回收或兑换饰品的服务。您在此确认并同意，不将GKDCSGO提供的任何产品或饰品通过任何路径兑换成法定货币（包括但不限于人民币）。您也同意，在GKDCSGO上各业务板块发生的积分消耗以及饰品的价值是合理的，符合您的期望，您无权向我们要求任何退换或赔偿。</p>
<p>&nbsp;</p>
<p>1.4在GKDCSGO上，您同意通过Valve Corporation或Valve S.a.r.l.（单称或合称&ldquo;Valve&rdquo;）提供的您的Steam账户登录并在GKDCSGO进行注册，您同意我们可以读取您Steam账户的一切公开信息及资产信息。我们与Valve及其关联方之间不存在任何形式的关联。您同意Steam&reg;用户协议的条款在所有方面都适用于您，特别包括Steam关于饰品提取/使用的一切规定。我们也提醒您关注Steam的隐私政策。另外，为了保障玩家的账户安全，提取环节可能存在身份核验、信息校对等流程性要求，请您及时关注及配合。若您需要任何帮助的，也可以随时联系GKDCSGO的客服，我们乐意进行解释并协助您完成提取。</p>
<p>&nbsp;</p>
<p>1.5您同意并认可，平台上的交易可能由于若干原因而失败，包括但不限于价格变化。我们不对任何交易的正常执行做出任何陈述或保证。我们特此声明：有权不负责因任何交易失败而产生、或与任何交易失败相关的所有损失和损害。您同意并承认我们并没有责任通知交易失败。我们可在任何时候拒绝执行交易、施加交易金额的限制或约束，无需事先通知，并可自行决定。具体而言，如果我们怀疑可能存在洗钱、赌博、资助恐怖主义、欺诈或任何其他非法行为，或者我们怀疑交易违反了相关服务条款，公司保留拒绝处理、取消或撤销任何交易和禁用用户帐户的权利。</p>
<p>&nbsp;</p>
<p>1.6您同意并认可，如果您或您的交易被我们标记为可疑，我们将要求您提供额外的证明文件，并可暂停您的任何交易、存款或提款，直至经我们自主判断排除任何可疑因素。您特此授权我们直接（或通过第三方）进行任何我们所认为必要的查询，以验证您的身份并/或防止欺诈、洗钱等违法违规行为的发生，包括查询您的身份信息（例如您的姓名、地址、过去地址或出生日期），查询与您关联的账户信息（例如姓名或账户余额)，并根据此类查询的结果采取我们认为合理且必要的行动。您还须授权可能收到此类查询或请求的任何和所有第三方对此类查询或请求做出全面响应。</p>
<p>&nbsp;</p>
<p>1.7您不得使用任何形式的未经授权的第三方软件、脚本或类似应用（包括但不限于自动化软件、修改器、抓取程序、用户脚本、插件、扩展、作弊软件等）以任何方式和/或为数据收集目的修改、自动化和/或改变购买程序，同时您不得在使用服务时采用价格转向或价格诱导等做法。一经发现您有任何前述行为，我们有权单方决定自动终止您的有关账户，且您无权获得补偿或取回任何C币、饰品或积分。</p>
<p>&nbsp;</p>
<p>1.8作为您使用网站和服务的条件之一，您承诺您不会：</p>
<p>1.冒充或虚报您与任何人士或实体的关联关系；</p>
<p>2.访问、篡改或使用网站和服务或我们的计算机系统的任何非公开区域；</p>
<p>3.试图探测、扫描或测试网站、服务或任何相关系统或网络的漏洞，或违反就网站、服务及该等系统和网络使用的任何安全或身份验证措施；</p>
<p>4.试图破译、反编译、反汇编、反向工程或另行调查用于提供网站或服务的任何软件或组件；</p>
<p>5.以任何方式损害或威胁损害其他用户，或干扰或试图干扰任何用户对主机或网络的访问，包括但不限于发送病毒、超载、洪泛、垃圾邮件或邮件炸弹，或其他方式；</p>
<p>6.提供属于第三方的支付信息；</p>
<p>7.以违背GKDCSGO的经营目的、我们的政策和说明、您对任何第三方的义务或任何适用法律的方式滥用我们的产品或服务；</p>
<p>8.从服务进行数据或其他内容的系统性回收，以直接或间接创建或编译单一或多个下载、集合、汇编、数据库、目录或类似程序，不论是手动抑或通过使用机器人（bots）、爬网程序（crawlers）、网页爬虫（spiders）或其他方式；</p>
<p>9.以违背第三方提供网站和服务运行的必要设施和技术（如第三方支付或Valve）的条款和条件的方式利用网站和服务；</p>
<p>10.在使用或访问网站或服务时侵犯我们及任何第三方的知识产权，包括但不限于利用服务提供C币、饰品或积分；</p>
<p>11.利用、推广、链接至我们自行决定认为具有攻击性或造成对我们声誉的损害的材料，或提供该等材料的访问，包括但不限于不合法内容、色情、赌博、诈骗、洗钱等内容及其他视为攻击性或有损我们和/或服务的内容</p>
<p>&nbsp;</p>
<p>1.9如您有我们自行决定认为不合法、违反本《用户协议》的条款，或另行对其他用户、对网站和服务的使用造成不利影响或潜在风险的任何行为或活动，我们有权随时终止您的账户或对网站或服务的全部或部分功能的使用（由我们自行决定）。您确认我们可以选择，但无义务在终止您对服务或网站的使用前向您发送通知。</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>二、帐号与密码安全</p>
<p>2.1账号注册：您在使用我们的服务时需要注册一个帐号。我们特别提醒您应妥善保管您的帐号和密码。当您使用完毕后，应安全退出。因您保管不善可能导致遭受盗号或密码失窃，责任由您自行承担。账户因您主动泄露或因您遭受他人诈骗等行为导致的损失及后果由您自行承担，我们并不承担责任，您可通过司法、行政等救济途径向侵权行为人主张权利。如需要我们提供必要协助的，请联系我们。</p>
<p>&nbsp;</p>
<p>2.2账号保管：您不应将自己的帐号、密码转让或出借予他人使用。如您发现自己的帐号遭他人非法使用，应立即通知我们，并有权通知我们采取措施暂停该账户的登录和使用。同时，您也应向我们提供与被停用账户注册信息相一致的个人有效身份信息。我们核实您所提供的个人有效身份信息与所注册的身份信息相一致的，应当及时采取措施暂停该账户的登录和使用。若您没有提供个人有效身份证件或者用户提供的个人有效身份信息与所注册的身份信息不一致的，我们有权拒绝上述请求。此外，因计算机病毒、黑客行为、或您的保管疏忽等行为导致帐号、密码遭他人非法使用，我们不承担任何责任。我们也特别提醒您妥善保存自己支付平台的账号及密码。若您发现任何第三方盗用您的支付信息进行充值的，您应第一时间联系您的支付服务提供商，对您的账号进行冻结并尽力阻止交易。若您的支付服务提供商确认您的账号系被盗用的，您可以向我们提供支付服务提供商出具的确认信息，我们也将尽力协助您减少损失。但请您谅解，在无法确认交易主体等交易细节的情况下，我们无法终止交易，您需要对账户或交易带来的任何损失承担责任。</p>
<p>进一步地，若您的账号被您的孩子/儿童误用/盗用的，请关注《关于不合规用户未成年人所涉退款问题公告》，您可以按照其中所述的流程与我们取得联系。</p>
<p>&nbsp;</p>
<p>2.3账户注销：您应当按照我们的注销指引进行注销。请注意，账号注销将会对您产生如下影响：</p>
<p>1.GKDCSGO账户一旦注销，您将无法登录、使用该账户（也包括无法再使用该账户作为第三方账户登录其他产品），也就是说您将无法再以此账户登录/使用/继续使用GKDCSGO相关的的相关产品与服务；</p>
<p>2.GKDCSGO账户一旦注销，您曾通过该账户登录、使用的GKDCSGO相关的产品与服务下的所有内容、信息、数据、记录将会被删除或匿名化处理，您也无法再检索、访问、获取、继续使用和找回，也无权要求我们找回（但法律法规另有规定或监管部门另有要求的除外），包括：</p>
<p>&bull;该账户下的个人资料（例如：头像、昵称等）及绑定信息（例如：绑定手机号、邮箱等）；</p>
<p>&bull;该账户下的您的个人信息（同时您亦不再享有《GKDCSGO隐私政策》中约定的您的用户权利，但法律法规另有规定的除外）；</p>
<p>&bull;其他所有内容、信息、数据、记录</p>
<p>3.您申请账户注销的行为即表明您同意放弃该账户在GKDCSGO相关的产品与服务使用期间已产生的但未消耗完毕的权益及未来的预期利益。即GKDCSGO账户一旦注销，我们将会对该账户下的全部权益做清除处理，包括：</p>
<p>&bull;您尚未到期的各类等级福利等；</p>
<p>&bull;您尚未使用的积分、卡券、等级额度等；</p>
<p>&bull;您GKDCSGO账户下的虚拟货币、饰品等；</p>
<p>&bull;您在GKDCSGO相关的产品与/或服务中发布的内容未来的收益权益；</p>
<p>&bull;您在GKDCSGO相关的产品与/或服务中的各类等级权益；</p>
<p>&bull;其他已产生但未消耗完毕的权益或未来预期的收益。</p>
<p>4.GKDCSGO账户一旦注销完成，将无法恢复。即使您在注销30个工作日后以同一手机号码再次向我们申请注册GKDCSGO账户，此时该账户为新的用户账户。因此，我们善意地提醒您在申请注销前对您账户进行处理。</p>
<p>5.GKDCSGO账户一旦注销，您与我们曾签署过的相关用户协议、其他权利义务性文件等相应终止（但我们与您之间已约定继续生效的或法律法规另有规定的除外）；</p>
<p>6.其他因GKDCSGO账户注销产生的结果。</p>
<p>7.GKDCSGO账户进入注销流程后，将在15个工作日之内为您完成注销，也就是说，在您已成功向提交了GKDCSGO账户注销申请后的15个工作日内（从成功提交申请之时起算），账户将被注销（本《用户协议》另有约定的除外）。在前述15个工作日内，您无法登录与使用该账户。但是，在此期间内，您可以通过联系我们来恢复账户的正常使用。如超过前述期限后，您未终止注销的，该账户将被永久注销。注销完成后，该账户相关的手机号、实名、邮箱或Steam账户等信息将在30个工作日内无法再次进行使用注册。</p>
<p>&nbsp;</p>
<p>2.4在您向我们申请注销GKDCSGO账户之前或正在申请注销GKDCSGO账户的过程中，为保障您的账户安全和财产权益，请您先行检查与确保您申请注销的GKDCSGO账户已经同时满足以下几点，包括：</p>
<p>1.该账户是通过我们的官方渠道注册、符合本《用户协议》及相关平台说明规范的账户；且为您本人注册的账户；</p>
<p>2.该账户内无资金，包括但不限于：</p>
<p>&bull;账户背包内饰品已被消费完毕；</p>
<p>&bull;积分已被消费完毕；</p>
<p>&bull;账户中的相关C币已被消费完毕；</p>
<p>&bull;账户内的其他资金均已消费完毕。</p>
<p>3.该账户内无未处理完毕的交易，包括：</p>
<p>&bull;背包提取中无未完成的订单；</p>
<p>&bull;无正在处理中的订单；</p>
<p>4.不存在任何未处理完毕的（被）投诉、举报，仲裁、诉讼等纠纷；</p>
<p>5.未处于非法的被检查状态或被限制状态；</p>
<p>6.其他为了保障您的账户安全和财产权益需要满足的内容；</p>
<p>上述问题您可首先尝试自行处理，如您无法处理的或在处理过程中有任何疑惑的，可联系我们协助处理。</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>三、用户个人信息保护</p>
<p>3.1保护您的个人信息安全是我们的一项基本原则。我们将根据《隐私政策》及相关法律法规的要求收集、储存、处理您的信息。具体方式请查阅我们于GKDCSGO公示的《隐私政策》。</p>
<p>&nbsp;</p>
<p>3.2您在注册帐号或使用服务的过程中，可能需要填写一些必要的信息。您需要填写真实的身份信息，若您填写的信息不完整，则无法使用服务或在使用过程中受到限制。您了解并同意，您有义务保持您提供的信息的真实性及有效性。您同意并认可若法律有明确规定要求我们必须对部分用户的信息进行核实的情况下，您应当配合提供最新、真实有效的信息。否则我们有权依约停止对您提供服务。</p>
<p>&nbsp;</p>
<p>3.3一般情况下，您可随时浏览、修改自己提交的信息，但出于安全性和身份识别（如号码申诉服务）的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。</p>
<p>&nbsp;</p>
<p>3.4我们将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。</p>
<p>&nbsp;</p>
<p>3.5未经您的同意，我们不会主动向我们及其关联方以外的任何公司、组织和个人披露您的个人信息，但法律法规另有规定的除外。</p>
<p>&nbsp;</p>
<p>3.6您同意并认可在不透露单个用户隐私资料的前提下，我们有权对包括您的数据在内的用户数据库进行技术分析。</p>
<p>&nbsp;</p>
<p>3.7未经您的许可，我们不得向任何第三方（不包括我们的关联方）公开或共享您的身份信息，但下列情况除外：</p>
<p>&bull;您授权我们披露的；</p>
<p>&bull;有关法令要求我们披露的；</p>
<p>&bull;司法机关或行政机关基于法定程序要求我们提供的；</p>
<p>&bull;我们为了维护自己合法权益而向您提起诉讼或者仲裁时；</p>
<p>&bull;为维护社会公众的利益;</p>
<p>&bull;其他法律法规规定的情形。</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>四、使用服务的方式</p>
<p>4.1您承诺以真实身份注册成为我们的用户，并保证所提供的个人身份资料信息真实、完整、有效，依据法律规定和必备条款约定对所提供的信息承担相应的法律责任。您以真实身份注册成为我们用户后，需要修改所提供的个人身份资料信息的，我们将及时、有效地为您提供该项服务。</p>
<p>&nbsp;</p>
<p>4.2您对所持账户产生的行为依法享有权利和承担责任（包括但不限于在线签署各类协议、发布信息、购买或出售商品或服务以及信息披露）。</p>
<p>&nbsp;</p>
<p>4.3除非您与我们另有约定，您同意服务仅为您个人使用。</p>
<p>&nbsp;</p>
<p>4.4您应当通过我们提供或认可的方式使用服务。您依本《用户协议》条款所取得的权利不可转让。</p>
<p>&nbsp;</p>
<p>4.5您不得使用未经我们授权的插件、外挂或第三方工具对GKDCSGO提供的全部或部分服务进行干扰、破坏、修改或施加其他影响。</p>
<p>&nbsp;</p>
<p>4.6您通过服务对程序的使用受制于某些已经包含在规则中的行为准则。这些规则将在我们官方网站公开并不定期更新，所有通过服务使用程序的用户必须遵守。您有义务知晓、了解并遵守这些行为规则。我们保留权利决定何种行为违反规则，并对该等行为采取其认为合适的相应的处理措施（包括但不限于警告、扣除积分、暂时或永久冻结账户、拒绝提供服务等）。我们保留随时修改这些行为规则的权利。</p>
<p>4.7您有权仅为个人用途使用服务和网站，但您无权未经我们的事先书面同意，以任何方式向其他方出售服务、授予在服务中的担保权益、或转让服务的复制品，或向他人出租、出借或许可服务，但本《用户协议》其他规定明确允许者除外。</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>五、按现状提供服务</p>
<p>5.1您同意我们的服务是按照现有技术和条件所能达到的现状提供的。我们会尽最大努力向您提供服务，确保服务的连贯性和安全性；但我们不能随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。</p>
<p>&nbsp;</p>
<p>5.2您同意我们的部分服务，是基于Valve Corporation系统的。对于我们无法预见的第三方风险，如Valve服务器被攻击、Valve接口的变更、Valve的封禁等，导致您权益受损的，我们不承担该责任。</p>
<p>&nbsp;</p>
<p>六、自备设备</p>
<p>6.1您应当理解，您使用我们的服务需自行准备与相关服务有关的终端设备（如电脑、调制解调器等装置），并承担所需的费用（如电话费、上网费等费用）。</p>
<p>&nbsp;</p>
<p>6.2您同意，您使用服务时会耗用您的终端设备和宽带等资源。</p>
<p>&nbsp;</p>
<p>七、第三方提供的产品或服务</p>
<p>7.1您在我们平台上使用第三方提供的产品或服务时，除遵守本《用户协议》约定外，还应遵守第三方的用户协议。我们和第三方对可能出现的纠纷在法律规定和约定的范围内各自承担责任。</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>八、服务中的软件</p>
<p>8.1您在使用服务的过程中可能需要下载软件，对于这些软件，我们给予您一项个人的、不可转让及非排他性的许可。您仅可为访问或使用服务的目的而使用这些软件。为了改善用户体验、保证服务的安全性及产品功能的一致性，我们可能会对软件进行更新。您应该将相关软件更新到最新版本，否则我们并不保证其能正常使用。</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>九、知识产权声明</p>
<p>9.1我们在服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权归我们所有，您在使用服务中所产生的内容的知识产权归您或相关权利人所有。除我们另行说明外，在法律允许的范围内，您免费授予我们非排他的、无地域限制的许可使用（包括存储、使用、复制、修订、编辑、发布、展示、翻译、分发上述信息或制作派生作品，以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内等）及可再许可第三方使用的权利，以及可以自身名义对第三方侵权行为取证及提起诉讼的权利。</p>
<p>&nbsp;</p>
<p>9.2除另有特别声明外，我们提供服务时所依托软件的著作权、专利权及其他知识产权均归我们所有。我们在服务中所使用的&ldquo;中然成星信息&rdquo;、&ldquo;GKDCSGO&rdquo;、&ldquo;GKDCSGO&rdquo;及GKDlogo形象等商业标识，其著作权或商标权归我们所有。上述及其他任何服务包含的内容的知识产权均受到法律保护，未经我们或相关权利人书面许可，任何人不得以任何形式进行使用或创造相关衍生作品。</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>十、用户违法行为</p>
<p>10.1您在使用服务时（特别包括GKDCSGO各平台及与GKDCSGO相关的各沟通群）须遵守法律法规，不得利用GKDCSGO的服务从事违法违规行为，包括但不限于：</p>
<p>(1)发布、传送、传播、储存危害国家安全统一、破坏社会稳定、违反公序良俗、侮辱、诽谤、淫秽、暴力、赌博、诈骗、洗钱以及任何违反国家法律法规的内容；</p>
<p>(2)发布、传送、传播、储存侵害他人知识产权、商业秘密等合法权利的内容；</p>
<p>(3)恶意虚构事实、隐瞒真相以误导、欺骗他人；</p>
<p>(4)发布、传送、传播广告信息及垃圾信息；</p>
<p>(5)其他法律法规禁止的行为。</p>
<p>&nbsp;</p>
<p>10.2您在使用服务时（特别包括GKDCSGO各平台及与GKDCSGO相关的各沟通群）若发生如下任何行为（以下简称&ldquo;不当行为&rdquo;）之一的，我们有权自主判断并根据情节严重程度，依据本《用户协议》及相关规定，对您的账号采取限制措施，包括但不限于：</p>
<p>(1)以某种方式暗示或伪称我们内部员工或某种特殊身份，企图得到不正当利益或影响其他用户权益的行为；</p>
<p>(2)在任何平台或通过任何途径将在GKDCSGO获得的任何产品，包括但不限于饰品、积分、C币等进行兑现或交易；</p>
<p>(3)在我们中使用、上传违法或不当词语、字符及其他内容的行为，包括用于账号命名；</p>
<p>(4)以任何方式破坏我们或影响GKDCSGO的正常进行，包括但不限于：</p>
<p>a) 违规及（或）恶意注册、登录GKDCSGO的行为，包括但不限于恶意批量注册GKDCSGO账号，以及以破坏服务器鉴权、恶意挤服等方式登录GKDCSGO行为；</p>
<p>b) 恶意实施可能导致GKDCSGO服务器宕机、卡顿，或导致其他GKDCSGO用户延迟等无法正常GKDCSGO的行为，如利用DDOS对GKDCSGO服务器或其他玩家进行网络流量攻击的行为等；</p>
<p>c) 利用GKDCSGO漏洞实施获益、破坏GKDCSGO环境或给其他玩家造成不良影响的行为；</p>
<p>d) GKDCSGO数据表现异常，影响GKDCSGO公平的其他行为。</p>
<p>（5）使用各种私服、外挂行为；</p>
<p>（6） 使用GKDCSGO同步器（具体包括但不限于使用键盘、鼠标等硬件同步器，以便可以使用一套键盘、鼠标，同时、同步控制多台电脑进行GKDCSGO的行为），以及使用其他各种可以让您在GKDCSGO效率或收益数据上表现异常的硬件；</p>
<p>（7） 传播非法言论或不当信息、造谣、诽谤、恶意诋毁我们、GKDCSGO平台和/或其用户；</p>
<p>（8） 盗取他人GKDCSGO账号、GKDCSGO账号内的任何物品；</p>
<p>（9） 进行GKDCSGO账号交易、账号共享等影响账号安全的行为；</p>
<p>（10） 在未经我们授权或认可的折扣渠道进行GKDCSGO充值或委托未获我们授权或认可的第三方代为充值，或者使用未经我们授权或认可的第三方软件充值。</p>
<p>（11） 违反本协议任何约定，或违反国家法律法规任何规定的行为；</p>
<p>（12） 其他在行业内被广泛认可的不当行为。</p>
<p>&nbsp;</p>
<p>10.3您不得在GKDCSGO上发布以下内容：</p>
<p>(1)国家禁止或限制的；</p>
<p>(2)侵犯他人知识产权或者合法权利的；</p>
<p>(3)网站明令禁止的。</p>
<p>&nbsp;</p>
<p>10.4如果我们发现或收到他人举报您发布的信息违反本协议第十条任何约定的，我们有权进行独立判断并采取技术手段予以删除、屏蔽或断开链接。同时，我们有权视您的行为性质，采取包括但不限于暂停或终止服务，限制、冻结或终止GKDCSGO账户使用，追究法律责任等措施。您违反本条约定，导致任何第三方损害的，您应当独立承担责任；我们因此遭受损失的（包括但不限于第三方直接向我们主张赔偿等情形），您也应当一并赔偿。如果相关国家机关、机构或相关权利人可能会对您提起诉讼、罚款或采取其他制裁措施，并要求我们给予协助。造成损害的，您应依法予以赔偿，我们不承担任何责任。</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>十一、遵守当地法律监管</p>
<p>11.1您在使用服务过程中应当遵守当地相关的法律法规，并尊重当地的道德和风俗习惯。如果您的行为违反了当地法律法规或道德风俗，您应当为此独立承担责任。您应避免因使用服务而使我们卷入政治和公共事件，否则我们有权单方决定暂停或终止对您的服务。</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>十二、用户发送、传播的内容与第三方投诉处理</p>
<p>12.1您通过服务发送或传播的内容（包括但不限于网页、文字、图片、音频、视频、图表等）均由您自行承担责任。您发送或传播的内容应有合法来源，相关内容为您所有或您已获得权利人的授权。您同意我们可为履行本《用户协议》或提供服务的目的而使用您发送或传播的内容。如果我们收到权利人通知，主张您发送或传播的内容侵犯其相关权利的，您同意我们有权进行独立判断并采取删除、屏蔽或断开链接等措施。</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>十三、不可抗力及其他免责事由</p>
<p>13.1您理解并同意，在使用服务的过程中，可能会遇到不可抗力等风险因素，使服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，我们将努力在第一时间与相关单位配合，及时进行修复，但是由此给您造成的损失我们在法律允许的范围内免责。在法律允许的范围内，我们对以下情形导致的服务中断或受阻不承担责任：</p>
<p>(1)受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；</p>
<p>(2)您或我们的电脑软件、系统、硬件和通信线路出现故障；</p>
<p>(3)您操作不当；</p>
<p>(4)您通过非我们授权的方式使用服务；</p>
<p>&nbsp;</p>
<p>13.2您理解并同意，在使用服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，我们不对任何信息的真实性、适用性、合法性承担责任，也不对因第三方的侵权行为给您造成的损害负责。这些风险包括但不限于：</p>
<p>(1)来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息；</p>
<p>(2)因使用本《用户协议》项下的服务，遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失；</p>
<p>(3)其他因网络信息或用户行为引起的风险。</p>
<p>&nbsp;</p>
<p>13.3我们仅向您提供平台服务，您了解平台上的信息系用户自行发布，且可能存在风险与瑕疵，我们将依法建立相关检查制度尽可能保障您在平台上的合法权益。鉴于平台具备海量信息以及用户发布的服务无明显特征进行辨别，我们无法进行逐一审查，对交易的真实性、合法性，您应谨慎判断，因此造成的您的损失，您可按相关法律法规规定，向侵权人主张您的合法权益，对此我们不承担责任。</p>
<p>&nbsp;</p>
<p>13.4您理解并同意，服务并非为某些特定目的而设计，包括但不限于核设施、军事用途、医疗设施、交通通讯等重要领域。如果因为软件或服务的原因导致上述操作失败而带来的人员伤亡、财产损失和环境破坏等，我们不承担法律责任。我们依据本《用户协议》约定获得处理违法违规内容的权利，该权利不构成我们的义务或承诺，我们不能保证及时发现违法行为或进行相应处理。在任何情况下，您不应轻信借款、索要密码或其他涉及财产的网络信息。涉及财产操作的，请一定先核实对方身份，并请经常留意我们有关防范诈骗犯罪的提示。</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>十四、协议的生效与变更</p>
<p>14.1您使用我们的服务即视为您已阅读本《用户协议》并接受本《用户协议》的约束。我们有权在必要时修改本《用户协议》条款，且将会发布通告告知您，您应及时查看修改后的协议，本《用户协议》条款变更后，如果您继续使用我们提供的软件或服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用我们提供的软件或服务。</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>十五、服务的变更、中断或终止</p>
<p>15.1鉴于网络服务的特殊性，您同意我们有权随时变更、中断或终止部分或全部的网络服务。如变更、中断或终止的网络服务，我们无需通知您，也无需对您或任何第三方承担任何责任，但如果上述行为直接影响到您的相关资金权利，我们将尽可能事先进行通告，您应当在收到通告后3日内联系平台客服或按照平台提供的相关处理方式进行处理，如您逾期未做任何处理将被视为自愿放弃相关权利。</p>
<p>&nbsp;</p>
<p>15.2 GKDCSGO（尤指但不仅指GKDCSGO平台虚拟物品交易平台及附带相关软件）的各个新版本，我们可以自行决定是否保留为我们服务增加附加特点和功能，或者提供程序编程之修补、更新和升级的权利。我们无需向您发出通知即可自行决定修改、停止或暂停您对任何版本我们服务的使用，和/或禁用您已经使用或安装的我们服务，以便对相关技术进行检修、改良和/或升级，或者出于其他合理原因；对于由发行和/或不发行我们服务的新版本而导致的直接或间接损害，中然成星（宁波）信息技术有限公司将不承担任何赔偿责任。</p>
<p>&nbsp;</p>
<p>15.3如发生下列任何一种情形，我们有权不经通知而暂缓或中断向您提供全部/部分服务：</p>
<p>(1)您的账号与平台存在尚未解决的争议；</p>
<p>(2)基于主管部门、司法机关、第三方的要求或主张；</p>
<p>(3)为保障账号安全排除风险所必要的暂缓或中断服务。</p>
<p>该等暂缓或中断提供服务的情况将在争议或主张消除或解决后尽快恢复。</p>
<p>&nbsp;</p>
<p>15.4如发生下列任何一种情形，我们有权不经通知而中断或终止向您提供全部/部分服务：</p>
<p>(1)根据法律规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；</p>
<p>(2)您违反相关法律法规或本《用户协议》的约定；</p>
<p>(3)按照法律规定或主管部门的要求；</p>
<p>(4)出于安全的原因或其他必要的情形。</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>十六、管辖与法律适用</p>
<p>16.1本《用户协议》签订地为中华人民共和国浙江省宁波市鄞州区。本《用户协议》的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。若您和我们之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本《用户协议》签订地有管辖权的人民法院管辖。本《用户协议》所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本《用户协议》涵义解释的依据。本《用户协议》条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>十七、其他</p>
<p>如果您对本《用户协议》或对GKDCSGO的产品或服务有任何意见或建议，请与我们联系：</p>
<p>您可以在网页任一界面，点击&ldquo;客服&rdquo;后，方可与我们取得联系！或发送邮件至kefu1@zrcxnb.com，我们会给予您必要的帮助！</p>
			
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name:'UnpackingRules',
		data(){
			return{
				
			}
		}
	}
</script>

<style lang="scss">
	.UnpackingRules-box{
	     width: 10rem;
	     margin: 0 auto;
	     padding-top: 0.4rem;
	     .UnpackingRules-box-info{
	         margin-top: 0.3rem;
	         .title{
	             font-size: 0.18rem;
	             color: #73d171;
	             height: 0.5rem;
	             line-height: 0.5rem;
	        }
	         p{
	             font-size: 0.16rem;
	             color: #fff;
	             line-height: 1.75em;
	        }
	    }
	}
	 
</style>
